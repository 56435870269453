<template>
  <div class="contact-wrapper" :class="{withTopBar: isTopBar}">
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end p-3 pr-5 px-md-5">
        <div>
          <h1>Predajné miesto <br>a kontakty</h1>
        </div>
      </div>
    </div>

    <div class="contact-info pt-4 p-2 p-md-5 d-flex flex-wrap">
      <div class="col-md-4 mb-4 mb-md-0 pl-md-0">
        <p class="font-weight-bold mb-4 d-flex align-items-center"><img src="../assets/images/pin-icon.svg"> Predajné miesto</p>
        <p class="font-weight-bold text-small mb-0">Penta Real Estate</p>
        <p class="text-small mb-0">Digital Park II</p>
        <p class="text-small m-0">Einsteinova 23</p>
        <p class="text-small m-0">Bratislava</p>
        <p class="text-small m-0">Slovenská republika</p>
        <p class="text-small m-0 mb-3"><a target="_blank" href="https://www.google.com/maps/place/Digital+Park+II,+Einsteinova+23,+851+01+Petr%C5%BEalka/@48.1301763,17.1050219,17z/data=!3m1!4b1!4m5!3m4!1s0x476c897221a35f19:0xfceab18ad724e22!8m2!3d48.1301763!4d17.1072106">Zobraziť na mape</a></p>
        <p class="text-small m-0">PO – PIA: 8.30 – 17.00</p>
        <p class="text-small m-0 mb-4">Sobota – Nedeľa: ZATVORENÉ</p>
        <p class="text-small mb-1 pr-5 font-weight-bold">Osobné stretnutie je ideálne dohodnúť si vopred.</p>
        <p class="text-small m-0 pr-5 font-weight-bold">Našich predajcov môžete kontaktovať aj telefonicky <br>alebo na mailovej adrese <a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a>.</p>
      </div>
      <div class="col-md-4 mb-4 mb-md-0">
        <p class="font-weight-bold mb-3 d-flex align-items-center"><img class="contact-icon" src="../assets/images/phone-call.png">Kontakt</p>
        <div class="row">
          <div class="col-md-6">

            <p class="m-0 mb-2 font-weight-bold stage">Bory Nový Dvor</p>

            <p class="text-small m-0 mt-3">Andrea Seléni</p>
            <p class="text-small m-0 mb-5"><a href="tel:+421904651427">+421 904 651 427</a></p>

            <p class="text-small d-none d-md-block m-0"><a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>
          </div>
          <div class="col-md-6">
              <p class="m-0 mb-2 font-weight-bold stage">Bory Na Hrádzi</p>
              <p class="text-small m-0 mt-3">Zuzana Bábelová</p>
              <p class="text-small mb-3"><a href="tel:+421904722539">+421 904 722 539</a></p>
              <p class="text-small d-block d-md-none m-0"><a href="mailto:byvanie@borybyvanie.sk">byvanie@borybyvanie.sk</a></p>
            </div>
        </div>
      </div>
      <div class="col-md-3 mb-4 mb-md-0">
        <img src="../assets/images/map_contact.svg">
      </div>
    </div>

    <ContactForm></ContactForm>

    <section class="developer-projects p-3 p-md-5">
      <h2>Projekty developera</h2>
      <div class="d-flex flex-wrap align-items-center pb-md-5 px-3 px-md-5">
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="https://skypark.sk/sk/home" target="_blank"><img src="../assets/images/projects/skypark-residence.png" alt="Projekt developera - Sky Park Residence"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="https://primyte.sk/home" target="_blank"><img src="../assets/images/projects/logo_pri-myte.png" alt="Projekt developera - Pri Mýte"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="http://www.zahorskesady.sk/" target="_blank"><img src="../assets/images/projects/ZS_logo.svg" alt="Projekt developera - Záhorske sady"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="http://www.novaterasa.sk/" target="_blank"><img src="../assets/images/projects/NT3.svg" alt="Projekt developera - Nová Terasa"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="https://skypark.sk/sk/kancelarie/o-projekte" target="_blank"><img src="../assets/images/projects/skypark-offices.png" alt="Projekt developera - Sky Park Offices"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="https://www.borymall.sk/sk/uvod" target="_blank"><img src="../assets/images/projects/logo_3.svg" alt="Projekt developera - Bory Mall"></a>
        </div>
        <div class="col-6 text-center col-md p-0 p-md-3 mb-5 mb-md-0">
          <a href="https://www.digitalpark.sk/" target="_blank"><img src="../assets/images/projects/digital-park.svg" alt="Projekt developera - Digital Park"></a>
        </div>
      </div>
      <div class="d-flex justify-content-center py-3">
        <b-button href="https://www.pentarealestate.com/sk/nase-projekty/slovenska-republika" target="_blank" squared variant="primary">Ďalšie projekty developera</b-button>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
name: "Contact",
  components: {ContactForm},
  data() {
    return {
      isTopBar: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    const anchor = this.$router.currentRoute.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        document.getElementById('contact-form').scrollIntoView({block: 'center'})
      }
    });
    this.isTopBar = getCookie('hideTopBar') == 1 ? false : true
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.developer-projects
  background: var(--textBright)
  img
    max-height: 100px
  h2
    font-size: 40px
    line-height: 45px
    letter-spacing: -1px
    font-weight: 400
    margin-bottom: 50px
    @media (max-width: $md)
      font-size: 32px
      line-height: 45px
      letter-spacing: -1px
.sample
  background: var(--textBright)
  .img-bg
    background: url(../assets/images/about-bottom-new.jpg) bottom/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      order: 1
  .text-left
    padding-bottom: 70px
    @media (max-width: $md)
      order: 2
  h3
    font-size: 18px
    line-height: 20px
    letter-spacing: -0.5px
    font-weight: 500
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 14px
      line-height: 18px
      letter-spacing: -0.25px
  p
    font-weight: 500
    margin: 0
    &.strong
      font-weight: 700
    &.big
      font-size: 55px
      line-height: 60px
      letter-spacing: -3px
      font-weight: 300
      max-width: 500px
      margin-bottom: 80px
      @media (max-width: $md)
        font-size: 40px
        line-height: 40px
        letter-spacing: -2px
        max-width: 100%
        margin-bottom: 30px
.contact-info
  background: var(--darkBlue)
  p
    img
      margin-right: 10px

      &.contact-icon
        max-height: 20px
    &.strong
      font-weight: 500
      letter-spacing: -0.25px
      @media (max-width: $md)
        font-size: 18px
        line-height: 22px
    &.text-small
      font-size: 16px
      line-height: 22px
      @media (max-width: $md)
        font-size: 16px
        line-height: 22px
        font-weight: 400
    a
      color: var(--primary)
.contact-wrapper
  padding-top: 85px
  background: var(--darkBlue)

  &.withTopBar
    padding-top: 127px

  .stage
    @media (max-width: $md)
      font-size: 20px
.home
  height: 60vh
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/images/bory-temp.jpg") no-repeat
  background-size: cover
  @media (max-width: $md)
    height: 70vh
    background-position: bottom center
  .intro-text
    text-align: left
    color: #fff
    margin-bottom: 50px
    @media (max-width: $md)
      margin-bottom: 20px
    h1
      font-size: 64px
      line-height: 60px
      letter-spacing: -3px
      font-weight: 800
      margin-bottom: 0
      @media (max-width: $md)
        font-size: 44px
        line-height: 44px
        letter-spacing: -2px
    p
      font-size: 18px
      line-height: 22px
      font-weight: 500
      max-width: 640px
      margin-bottom: 30px
</style>